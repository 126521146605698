import * as React from "react"
import { graphql, Link } from "gatsby"


const OGImageTemplate = ({ data, location }) => {
  const incident = data.markdownRemark.frontmatter
  const name = incident.Name
  const verdict = incident.Attack
  const slug = data.markdownRemark.fields.slug
  const color = verdict === "No" ? null: "color-yes"

  return (
    <div className="global-wrapper-og">
      <header><h1 className="main-heading-og">
        Was <Link className="incident" to={slug}>{name}</Link> a <Link className="definition" to="/what">Cyber
        Attack?</Link>
      </h1></header>
      <div id="verdict-og"><h1 className={color}>{verdict}</h1></div>
    </div>
  )
}

export default OGImageTemplate

export const pageQuery = graphql`
  query OgImageBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        metaTitle
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        Attack
        Name
        Date
        Group
      }
    }
   
  }
`